
import React from 'react';
import Hero from 'blocks/Hero';
import Text from 'blocks/Text';
import Columns from 'blocks/Columns';
import Layout from 'blocks/Layout';
import Gallery from 'blocks/Gallery';
import Default from 'blocks/Default';

const availableBlocks = {
  'Hero': Hero,
'Text': Text,
'Columns': Columns,
'Layout': Layout,
'Gallery': Gallery,
};

const Blocks = ({block, rowIndex}) => {
    const blockName = block.__typename.replace(/.*_([a-z]*)$/gi, '$1');

    return availableBlocks[blockName]
        ?  React.createElement(availableBlocks[blockName], {Blocks, rowIndex, ...block})
        : <Default blockType={blockName}/>;
};

export default Blocks;
